import EnMessages from './locales/en/en.json';
import FrMessages from './locales/fr/fr.json';
import DeMessages from './locales/de/de.json';
import NlMessages from './locales/nl/nl.json';
import En_CaMessages from './locales/en-CA/en-CA.json';
import Fr_CaMessages from './locales/fr-CA/fr-CA.json';

const AppLocale = {
  en: EnMessages,
  fr: FrMessages,
  de: DeMessages,
  nl: NlMessages,
  'en-CA': En_CaMessages,
  'fr-CA': Fr_CaMessages,
};

export default AppLocale;
