export const formatMoney = (
  currency: string,
  locale: string,
  value: number
): string | null => {
  if (currency && locale) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(value);
  }
  return null;
};
