import React, { Component } from 'react';
import { Radio } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import SessionGroupModel from '../../Models/Sessions/SessionGroupModel';
import EmisysButton from '../EmisysButton/EmisysButton';
import { formatDateToLongMonthString } from '../../services/formatDate';
import { shopConfig } from '../../Globals/ShopConfig';
import Translator from '../../services/translator';
import '../../Models/SessionModel';
import '../../Models/Sessions/SessionGroupModel';
import './Sessions.css';

class SessionSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Groups at the top level: tree with all the groups leading to schedules
      topGroups: [],
      // Currently selected range down the tree
      groups: [],
      // Schedules grouped by page
      schedulePages: [],
      value: [],
      // Currently selected group or null if at top level
      currentGroup: null,
      // Currently displayed schedule page or null if a group is displayed
      currentSchedulesPage: null,
    };
    this.showAvailabilityIndicators = shopConfig.pos.showAvailabilityIndicators;
  }

  componentDidMount() {
    /*
     * TODO: Use this.divElement.clientWidth or window.innerWidth to decide if the device is mobile or desktop and
     * adjust the maximum number of buttons to show.
     * The number of buttons is returned by requiredButtons(). It needs to be adjusted based on the finding here.
     * BEWARE that a mobile can display portrait or landscape. Use Math.min(window.innerWidth, window.innerHeight)?
     * BEWARE that some mobiles have a very large innerWidth/innerHeight exceeding the height of a small desktop.
     * See possible solution not relying on screen width: https://stackoverflow.com/a/60932511/4452244
     * console.log('client width ' + this.divElement.clientWidth);
     * console.log('window width ' + window.innerWidth);
     */
    if (this.props.currentSchedule) {
      this.buildSessionGroups();
      this.autoSelectSchedule();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentSchedule !== prevProps.currentSchedule) {
      this.buildSessionGroups();
      this.autoSelectSchedule();
    }
  }

  autoSelectSchedule = () => {
    const currentScheduleKeys = Object.keys(this.props.currentSchedule);
    if (currentScheduleKeys.length === 1) {
      this.setState({ value: [currentScheduleKeys[0]] });
      this.props.onClickSchedule(
        this.props.currentSchedule[currentScheduleKeys[0]]
      );
    } else {
      this.setState({ value: [] });
    }
  };

  buildSessionGroups = () => {
    const { currentSchedule } = this.props;
    const maxButtons = this.requiredGroupButtons();
    const schedulesPages = this.formatSchedulesPages(currentSchedule);
    const groups = SessionGroupModel.buildFromSessions(
      maxButtons,
      schedulesPages
    );
    if (groups.length === 0) {
      this.setState({
        topGroups: [],
        groups: [],
        schedulePages: schedulesPages,
        currentGroup: null,
        currentSchedulesPage: 0,
      });
    } else {
      this.setState({
        topGroups: groups,
        groups: groups,
        schedulePages: schedulesPages,
        currentGroup: null,
        currentSchedulesPage: null,
      });
    }
  };

  /**
   * Return the maximum number of buttons to display on one page to select sessions.
   * If there are more sessions than this number, groups are displayed instead of sessions. The user then drills down
   * until this maximum number of sessions are displayed for the selected time range.
   * Less buttons can be displayed if the algorithm decides to do so.
   * @returns {number} maxButtons
   */
  requiredSchedulesButtons = () => {
    // TODO: Should be 5 buttons on mobile and 9 on desktop
    return 10;
  };

  requiredGroupButtons = () => {
    // TODO: Should be 5 buttons on mobile and 9 on desktop
    return 10;
  };

  returnToTopGroup = () => {
    const { topGroups } = this.state;
    if (topGroups.length > 0) {
      this.setState({
        groups: topGroups,
        currentGroup: null,
        currentSchedulesPage: null,
      });
    }
  };

  onClickGroup = (value) => {
    const { groups } = this.state;
    /** @type {SessionGroupModel} */
    const group = groups[value];
    const page = group.page;
    if (group.hasGroups()) {
      this.setState({
        groups: group.groups,
        currentGroup: group,
        currentSchedulesPage: null,
      });
    } else {
      this.setState({
        groups: [],
        currentGroup: group,
        currentSchedulesPage: page,
      });
    }
  };

  /**
   * Group sessions into pages of at most this.requiredButtons() sessions.
   * @param {Object.<string, SessionModel[]>} sessionsList
   * @returns {Object.<string, SessionModel[]>[]}
   */
  formatSchedulesPages = (sessionsList) => {
    let formattedList = [];
    let requiredButtons = this.requiredSchedulesButtons();
    let currentPage = {};
    formattedList.push(currentPage);
    Object.keys(sessionsList).map((key) => {
      if (Object.keys(currentPage).length >= requiredButtons) {
        currentPage = {};
        formattedList.push(currentPage);
      }
      if (!currentPage.hasOwnProperty(key)) {
        currentPage[key] = [];
      }
      sessionsList[key].forEach((session) => currentPage[key].push(session));
    });
    return formattedList;
  };

  onGroupUp = () => {
    const { currentGroup, topGroups } = this.state;
    if (currentGroup !== null) {
      const parentGroup = this.findParentGroup(topGroups, currentGroup);
      const groups = parentGroup ? parentGroup.groups : topGroups;
      this.setState({
        groups: groups,
        currentGroup: parentGroup,
        currentSchedulesPage: null,
      });
    }
  };

  /**
   * @param {SessionGroupModel[]} topGroups
   * @param {SessionGroupModel} currentGroup
   * @returns {?SessionGroupModel}
   */
  findParentGroup(topGroups, currentGroup) {
    if (topGroups.some((group) => group === currentGroup)) {
      // top group found: no parent
      return null;
    }
    let parentGroup = null;
    topGroups.find((group) => {
      const subGroup = this.findParentGroupRecursive(group, currentGroup);
      if (subGroup) {
        parentGroup = subGroup;
        return true;
      }
      return false;
    });
    return parentGroup;
  }

  /**
   * @param {SessionGroupModel} higherGroup
   * @param {SessionGroupModel} currentGroup
   * @returns {?SessionGroupModel}
   */
  findParentGroupRecursive(higherGroup, currentGroup) {
    let parentGroup = null;
    higherGroup.groups.find((group) => {
      if (group === currentGroup) {
        parentGroup = higherGroup;
        return true;
      }
      const subGroup = this.findParentGroupRecursive(group, currentGroup);
      if (subGroup) {
        parentGroup = subGroup;
        return true;
      }
      return false;
    });
    return parentGroup;
  }

  onClickRadio = (value) => {
    let needToScroll = false;
    const { currentSchedule, currentSession } = this.props;
    let newValue = [...this.state.value];

    if (newValue.some((data) => data === value)) {
      newValue.forEach(() => {
        const index = newValue.indexOf(value);
        if (index > -1) {
          newValue.splice(index, 1);
        }
      });
    } else {
      if (currentSession?.placementEventKey) {
        newValue = [value];
      } else {
        newValue.push(value);
      }
      needToScroll = true;
    }

    if (newValue.length < 1) {
      this.props.showArrow(false);
    }

    this.setState({ value: newValue }, () => {
      if (needToScroll) {
        this.props.showArrow(true);
        document
          .querySelector('.drawer-calendar-schedule')
          .scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    });

    this.props.onClickSchedule(currentSchedule[value]);
  };

  previousParentGroup = () => {
    const { topGroups, currentSchedulesPage } = this.state;
    if (currentSchedulesPage > 0) {
      const newPage = currentSchedulesPage - 1;
      this.setState({
        currentGroup: this.findGroupWithPage(topGroups, newPage),
        currentSchedulesPage: newPage,
      });
    }
  };

  nextParentGroup = () => {
    const { topGroups, currentSchedulesPage, schedulePages } = this.state;
    if (currentSchedulesPage + 1 < schedulePages.length) {
      const newPage = currentSchedulesPage + 1;
      this.setState({
        currentGroup: this.findGroupWithPage(topGroups, newPage),
        currentSchedulesPage: newPage,
      });
    }
  };

  /**
   * @param {SessionGroupModel[]} groups
   * @param {number} page
   * @returns {?SessionGroupModel}
   */
  findGroupWithPage(groups, page) {
    let foundGroup = null;
    groups.some((group) => {
      if (group.page === page) {
        foundGroup = group;
        return true;
      }
      const subGroup = this.findGroupWithPage(group.groups, page);
      if (subGroup !== null) {
        foundGroup = subGroup;
        return true;
      }
      return false;
    });

    return foundGroup;
  }

  getQtyRemaining(sessionsList) {
    let qtyRemaining = null;
    sessionsList.forEach((session) => {
      if (session.qtyRemaining !== null && session.qtyRemaining > 0) {
        qtyRemaining += session.qtyRemaining;
      }
    });
    return qtyRemaining;
  }

  renderQtyRemaining(sessionsList) {
    let qtyRemaining = this.getQtyRemaining(sessionsList);

    if (qtyRemaining !== null) {
      if (qtyRemaining > 0) {
        return Translator.trans('product.qtyRemainingTicket.quantity', {
          quantity: qtyRemaining,
        });
      } else {
        return Translator.trans('product.qtyRemainingTicket.outOfStock');
      }
    }
  }

  formatLabel(label) {
    const labelArray = label.split('-');
    return (
      <>
        <span className={'session-hour-start'}>{labelArray[0]}</span>
        <span className={'session-hour-split'}>-</span>
        <span className={'session-hour-end'}>{labelArray[1]}</span>
      </>
    );
  }

  formatLabelTimeslot(label) {
    const labelArray = label.split('-');
    return (
      <>
        <span className={'timeslot-hour-start'}>{labelArray[0]}</span>
        <span className={'timeslot-hour-split'}>
          <ArrowRightOutlined />
        </span>
        <span className={'timeslot-hour-end'}>{labelArray[1]}</span>
      </>
    );
  }

  render() {
    const { groups, schedulePages, currentGroup, currentSchedulesPage } =
      this.state;
    let schedulesLength = 0;

    if (currentSchedulesPage !== null) {
      schedulesLength = Object.keys(schedulePages[currentSchedulesPage]).length;
    } else {
      schedulesLength = Object.keys(groups).length;
    }

    return (
      <>
        <div className={'session-schedule-title'}>
          {currentSchedulesPage !== null
            ? Translator.trans('session.select_session')
            : Translator.trans('session.select_schedule')}
        </div>

        <div className={'container-sessions-selection'}>
          <div className={'back-page-sessions-selection'}>
            {currentSchedulesPage !== null && currentSchedulesPage > 0 && (
              <ArrowLeftOutlined
                className={'back-icon-session-selection'}
                onClick={() => this.previousParentGroup()}
              />
            )}
          </div>
          <div className={'container-session-day-selected'}>
            <div
              className={'session-date-timeslot-selected'}
              onClick={() => this.returnToTopGroup()}
            >
              {formatDateToLongMonthString(this.props.currentDate, true)}
              <br />
              {currentGroup?.label}
            </div>
            <div
              className={`session-schedule-container ${
                schedulesLength < 2 && 'alone'
              }`}
              ref={(divElement) => {
                this.divElement = divElement;
              }}
            >
              {currentSchedulesPage === null
                ? Object.keys(groups).map((key, index) => (
                    <Radio.Button
                      key={index}
                      onClick={() => this.onClickGroup(key)}
                      disabled={
                        groups[key].qtyRemaining !== null &&
                        groups[key].qtyRemaining <= 0
                      }
                    >
                      {this.formatLabelTimeslot(groups[key].label)}
                    </Radio.Button>
                  ))
                : schedulePages[currentSchedulesPage]
                ? Object.keys(schedulePages[currentSchedulesPage]).map(
                    (key, index) => (
                      <Radio.Button
                        key={index}
                        onClick={() => this.onClickRadio(key)}
                        checked={this.state.value.some(
                          (value) => value === key
                        )}
                        disabled={schedulePages[currentSchedulesPage][
                          key
                        ].every(
                          (session) =>
                            session.qtyRemaining !== null &&
                            session.qtyRemaining <= 0
                        )}
                      >
                        <div
                          className={
                            this.getQtyRemaining(
                              schedulePages[currentSchedulesPage][key]
                            )
                              ? 'container-session-with-qty'
                              : 'container-session-without-qty'
                          }
                        >
                          <div className={'session-label'}>
                            {this.formatLabel(key)}
                          </div>
                          {this.showAvailabilityIndicators ? (
                            <div className={'session-qty-remaining'}>
                              {this.renderQtyRemaining(
                                schedulePages[currentSchedulesPage][key]
                              )}
                            </div>
                          ) : null}
                        </div>
                      </Radio.Button>
                    )
                  )
                : null}
            </div>
            {currentGroup && (
              <div className={'button-timeslot-session-bottom-container'}>
                <EmisysButton
                  className={'button-timeslot-session-bottom'}
                  isOutlined
                  onClick={() => this.onGroupUp()}
                >
                  {Translator.trans('session.timeslot')}
                </EmisysButton>
              </div>
            )}
          </div>
          <div className={'next-page-sessions-selection'}>
            {currentSchedulesPage !== null &&
              currentSchedulesPage + 1 < Object.keys(schedulePages).length && (
                <ArrowRightOutlined
                  className={'next-icon-session-selection'}
                  onClick={() => this.nextParentGroup()}
                />
              )}
          </div>
        </div>
      </>
    );
  }
}

export default SessionSchedule;
