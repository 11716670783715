import { appLanguagesType } from '../Models/ShopConfig/ShopLocaleModel';

export function getBestSupportedLanguage(
  locale: string,
  appLanguages: appLanguagesType[],
  defaultLanguage: string
): string {
  if (languageExistInApp(appLanguages, locale)) {
    return locale;
  }
  const lang = locale.split(/[-_]/)[0];
  if (languageExistInApp(appLanguages, lang)) {
    return lang;
  }
  if (languageExistInApp(appLanguages, defaultLanguage)) {
    return defaultLanguage;
  }

  return appLanguages[0].lang;
}

const languageExistInApp = (appLanguages: appLanguagesType[], lang: string) => {
  return appLanguages.some((appLang) => appLang.lang === lang);
};
