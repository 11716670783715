import React, { useCallback, useEffect, useState, lazy, Suspense } from 'react';
import {
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useMatch,
} from 'react-router-dom';
import ScrollToTop from '../../services/ScrollToTop';
import ErrorPage from '../../components/ErrorPage/ErrorPage';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {
  initializeGoogleAnalytics,
  initializeFacebookPixel,
  initializeGoogleTagManager,
  grantConsent,
} from '../../services/tracking';
import { ErrorBoundary } from 'react-error-boundary';
import {
  shopIsIframe,
  setIframeShop,
  setShopConfig,
  shopConfig,
} from '../../Globals/ShopConfig';
import Page, { PageProps } from './Page';
import { pagesConstant, shopConstants } from '../../Constants/Constants';
import { get, getUrl } from '../../services/httpService';
import ShopConfigModel from '../../Models/ShopConfig/ShopConfigModel';
import { ConfigProvider } from 'antd';
import { getAntdLocale } from '../../services/getAntdLocale';
import LoadingShop from '../../components/LoadingShop/LoadingShop';
import initTranslations from '../../services/initTranslations';
import initColors from '../../services/initColors';
import { getConfigParams } from '../../services/parseUrl';
import Translator from '../../services/translator';
import {
  getPreviousPath,
  getShopTokenStorage,
} from '../../services/handleStorage';
import {
  addTokenToStorage,
  existTokensInStorage,
  removeTokenToStorage,
} from '../../services/handleTokens';
import history from '../../services/history';
import InAppSpy from 'inapp-spy';
let antdLocale = null;

import BasketPage from '../Basket';
import { addCustomCssToShop } from '../../services/addCustomCss';
import PreviewBanner from '../../components/PreviewBanner/PreviewBanner';
import OrderDetail from '../Orders/OrderDetail/OrderDetail';
import Wallpaper from '../../components/Wallpaper/Wallpaper';
import { ensureValidToken } from '../../services/parseJwt';
import ModalIntent from '../../components/Modal/ModalIntent';
import UserBarcodesPage from '../UserBarcodes';
import ForwardAssign from '../UserBarcodes/ForwardAssign/ForwardAssign';
const Account = lazy(() => import('../Account'));
const OrdersPage = lazy(() => import('../Orders'));
const Products = lazy(() => import('../Products'));
const AuthenticationPage = lazy(() => import('../Authentication'));
const AccommodationDetail = lazy(() => import('../Details/Accommodation'));
const TicketDetail = lazy(() => import('../Details/Tickets'));
const Cms = lazy(() => import('../../components/Footer/Cms'));
const PaymentConfirmation = lazy(
  () => import('../Confirmation/Payment/PaymentConfirmation')
);
const OrderConfirmation = lazy(
  () => import('../Confirmation/Order/OrderConfirmation')
);
const EventPage = lazy(() => import('../Event'));
const Checkout = lazy(() => import('../Checkout/Checkout'));

let currentHeight = 0;
const RoutesPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [intent, setIntent] = useState(false);
  const [showShop, setShowShop] = useState(false);
  const [showShopErrorMessage, setShowShopErrorMessage] = useState('');
  const isCheckoutUrl = useMatch('/checkout');
  const isLoaderUrl = useMatch('/loader');
  const previewKey = sessionStorage.getItem('previewKey');
  const { isInApp, appKey } = InAppSpy();

  useEffect(() => {
    if (isInApp && appKey !== undefined) {
      setIntent(true);
    }
  }, [isInApp, appKey]);

  const getShopConfig = useCallback((secondAttempt?: boolean) => {
    ensureValidToken().then(() => {
      get(getUrl('/config/shop', getConfigParams(window.location.pathname)), {
        no401Redirect: true,
      })
        .then((response) => {
          setShopConfig(new ShopConfigModel(response));
          setShowShop(true);

          setDarkMode(false); // TODO: set dark mode with shop config

          initTranslations(shopConfig.locale.language);
          initColors(shopConfig.branding);
          antdLocale = getAntdLocale();

          initializeGoogleAnalytics(shopConfig.analytics.googleAnalytics);
          initializeGoogleTagManager(shopConfig.analytics.googleTagManager);
          initializeFacebookPixel(shopConfig.analytics.facebookPixel);
          addCustomCssToShop(shopConfig.branding.customCss);
          shopConfig.locale.setAntdLocale(antdLocale);

          if (shopIsIframe) {
            grantConsent();
          }

          if (shopConfig.pos.preview) {
            let previewKey = new URLSearchParams(window.location.search).get(
              'previewKey'
            );
            if (previewKey) {
              sessionStorage.setItem('previewKey', previewKey);
            }
          } else {
            sessionStorage.removeItem('previewKey');
          }
        })
        .catch((error) => {
          if (error.status === 401 && !secondAttempt) {
            removeTokenToStorage();
            getShopConfig(true);
          } else if (error.body?.message) {
            setShowShopErrorMessage(error.body.message);
          } else {
            setShowShopErrorMessage(
              Translator.trans('error.internal_error.title')
            );
          }
          console.error('Get config error : ', error);
        });
    });
  }, []);

  const repeatOften = useCallback(() => {
    if (currentHeight !== document.body.scrollHeight) {
      currentHeight = document.body.scrollHeight;
      parent.postMessage(
        { type: 'emisys_audience_size', value: document.body.scrollHeight },
        '*'
      );
    }

    requestAnimationFrame(repeatOften);
  }, []);

  useEffect(() => {
    try {
      top.location.href;
    } catch (e) {
      console.info('Ticketing is in Iframe');
      setIframeShop(true);

      requestAnimationFrame(repeatOften);
    }
  }, [repeatOften]);

  useEffect(() => {
    setShowShopErrorMessage('');
    getShopConfig();
  }, [getShopConfig]);

  useEffect(() => {
    history.navigate = navigate;
    history.location = location;
  }, [navigate, location]);

  const CheckRoute = (props: PageProps): React.ReactElement => {
    const shopToken = getShopTokenStorage();
    if (shopConfig.pos.closed && (!shopConfig.pos.preview || !previewKey)) {
      return <Navigate to={pagesConstant.shop.closed} />;
    }

    if (shopConfig.project.canceled) {
      return <Navigate to={pagesConstant.shop.canceled} />;
    }

    if (shopConfig.pos.requirePassword && !shopToken) {
      return <Navigate to={pagesConstant.shop.private} />;
    }

    return <Page translation={props.translation}>{props.children}</Page>;
  };

  const AuthRoute = (props: PageProps): React.ReactElement => {
    if (existTokensInStorage()) {
      const previousPath = getPreviousPath();
      return <Navigate to={previousPath} />;
    } else {
      return <Page translation={props.translation}>{props.children}</Page>;
    }
  };

  const getDefaultUrl = (): [boolean, string] => {
    const getUrl = new URLSearchParams(window.location.search);
    const currentAction = getUrl.get('action');
    getUrl.delete('action');
    const searchParams = getUrl.toString();

    switch (currentAction) {
      case 'forgot-password':
        return [
          false,
          pagesConstant.auth + shopConstants.RESETPASSWORD + '?' + searchParams,
        ];
      case 'order-success':
        return [false, pagesConstant.payment.success + '?' + searchParams];
      case 'payment-checkout':
        return [false, pagesConstant.checkout + '?' + searchParams];
      case 'payment-success':
        return [false, pagesConstant.payment.success + '?' + searchParams];
      case 'payment-fail':
        return [false, pagesConstant.payment.failure + '?' + searchParams];
      case 'product':
        return [false, pagesConstant.tickets.home + '?' + searchParams];
      case 'order-list':
        return [false, pagesConstant.order.list];
      case 'order-show': {
        const id = getUrl.get('id');
        const signature = getUrl.get('signature');

        if (id && signature) {
          return [false, pagesConstant.order.detail + id + '/' + signature];
        } else {
          return [false, pagesConstant.tickets.home];
        }
      }
      case 'barcode-list':
        return [false, pagesConstant.account.tickets];
      case 'barcode-forwarded':
        return [false, pagesConstant.account.forward + '?' + searchParams];
    }
    return [false, pagesConstant.tickets.home + '?' + searchParams];
  };

  const setDarkMode = (isDark: boolean) => {
    let body = document.body;

    if (isDark) {
      body.classList.add('dark');
    } else {
      body.classList.remove('dark');
    }
  };

  let [noWrapper, defaultUrl] = getDefaultUrl();

  if (isCheckoutUrl || shopIsIframe || isLoaderUrl) {
    noWrapper = true;
  }

  useEffect(() => {
    // @ts-ignore
    const injectedObject = window?.ReactNativeWebView?.injectedObjectJson();

    if (injectedObject) {
      const webviewRN = JSON.parse(injectedObject);
      const appUser = webviewRN.getLoggedAuth;

      if (appUser) {
        addTokenToStorage(appUser);
      }
    }
  }, []);

  return showShop ? (
    <ConfigProvider
      locale={antdLocale}
      theme={{
        token: { colorPrimary: shopConfig?.branding.colorPrimary },
      }}
    >
      <ScrollToTop>
        <ErrorBoundary
          FallbackComponent={(exception) => (
            <ErrorPage exception={exception} error={exception.error.message} />
          )}
        >
          <Wallpaper>
            <div
              className={
                !shopIsIframe ? 'shop-container' : 'shop-iframe-container'
              }
            >
              {shopConfig.pos.preview && previewKey && <PreviewBanner />}
              {noWrapper || <Header />}
              <Suspense fallback={<LoadingShop isLazy />}>
                <main
                  className={'shop-main-container'}
                  style={!shopIsIframe ? { maxWidth: '1140px' } : null}
                  lang={shopConfig.locale.language}
                >
                  <Routes>
                    <Route
                      path={pagesConstant.loading.home}
                      element={<LoadingShop />}
                    />
                    <Route
                      path={pagesConstant.tickets.home}
                      element={
                        <CheckRoute>
                          <Products />
                        </CheckRoute>
                      }
                    />

                    <Route
                      path={pagesConstant.account.home}
                      element={
                        <Page translation={'page.titles.account'}>
                          <Account />
                        </Page>
                      }
                    />

                    <Route
                      path={pagesConstant.payment.response + ':response'}
                      element={
                        <CheckRoute translation={'page.titles.payment'}>
                          <PaymentConfirmation />
                        </CheckRoute>
                      }
                    />

                    <Route
                      path={pagesConstant.order.response + ':response'}
                      element={
                        <CheckRoute translation={'page.titles.order'}>
                          <OrderConfirmation />
                        </CheckRoute>
                      }
                    />

                    <Route
                      path={pagesConstant.basket}
                      element={
                        <CheckRoute translation={'page.titles.basket'}>
                          <BasketPage />
                        </CheckRoute>
                      }
                    />

                    <Route
                      path={pagesConstant.account.tickets}
                      element={
                        <Page translation={'page.titles.account.tickets'}>
                          <UserBarcodesPage />
                        </Page>
                      }
                    />

                    <Route
                      path={pagesConstant.account.forward}
                      element={
                        <Page translation={'page.titles.account.forward'}>
                          <ForwardAssign />
                        </Page>
                      }
                    />

                    <Route
                      path={pagesConstant.order.list}
                      element={
                        <Page translation={'page.titles.orders'}>
                          <OrdersPage />
                        </Page>
                      }
                    />

                    <Route
                      path={pagesConstant.order.detail + ':id/:signature'}
                      element={
                        <Page translation={'page.titles.order'}>
                          <OrderDetail />
                        </Page>
                      }
                    />

                    <Route
                      path={pagesConstant.accommodation.detail + ':id'}
                      element={
                        <CheckRoute translation={'page.titles.detail'}>
                          <AccommodationDetail />
                        </CheckRoute>
                      }
                    />

                    <Route
                      path={pagesConstant.tickets.detail + ':id'}
                      element={
                        <CheckRoute translation={'page.titles.detail'}>
                          <TicketDetail />
                        </CheckRoute>
                      }
                    />

                    {shopConfig.pos.closed && (
                      <Route
                        path={pagesConstant.shop.closed}
                        element={
                          <Page translation={'page.titles.closed'}>
                            <EventPage isClosed />
                          </Page>
                        }
                      />
                    )}
                    {shopConfig.project.canceled && (
                      <Route
                        path={pagesConstant.shop.canceled}
                        element={
                          <Page translation={'page.titles.canceled'}>
                            <EventPage isCanceled />
                          </Page>
                        }
                      />
                    )}
                    {shopConfig.pos.requirePassword && (
                      <Route
                        path={pagesConstant.shop.private}
                        element={
                          <Page translation={'page.titles.private'}>
                            <EventPage isPrivate />
                          </Page>
                        }
                      />
                    )}

                    <Route
                      path={pagesConstant.auth + ':loginType'}
                      element={
                        <AuthRoute translation={'page.titles.auth'}>
                          <AuthenticationPage />
                        </AuthRoute>
                      }
                    />

                    <Route
                      path={pagesConstant.page + ':page'}
                      element={
                        <Page translation={'page.titles.more'}>
                          <Cms />
                        </Page>
                      }
                    />

                    <Route
                      path={pagesConstant.checkout}
                      element={<Checkout />}
                    />

                    <Route path="/*" element={<Navigate to={defaultUrl} />} />
                  </Routes>
                </main>
              </Suspense>
              {noWrapper || <Footer />}
            </div>
          </Wallpaper>
        </ErrorBoundary>
      </ScrollToTop>

      <ModalIntent isOpen={intent} />
    </ConfigProvider>
  ) : (
    <LoadingShop error={showShopErrorMessage} />
  );
};

export default RoutesPage;
